import React from "react"

import socialImage from "../../static/DECAZES-social-share.jpg"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import Vin from "../components/Vin"

const VinPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Vin"
        description="Découvrez le vin de la famille Decazes :  Château La Grave - Duc Decazes - Grand Vin de Bordeaux "
        image={socialImage}
      />
      <Vin data={data} />
    </Layout>
  )
}

export default VinPage

export const query = graphql`
  query getVinData {
    file(relativePath: { eq: "bouteilles.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
